import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Location } from "@reach/router"

const NotFoundPage = () => (
  <Location>
    {props => (
      <Layout {...props}>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )}
  </Location>
)

export default NotFoundPage
